import React, { Component } from 'react'
import HTMLReactParser from 'html-react-parser';

export default class HtmlContent extends Component {
    render() {
        if (this.props.html && this.props.html) {
            return (
                HTMLReactParser(this.props.html)
            )
        }
        return (
            <p>
                It seems i forgot to add the HTML content
            </p>
        )
    }
}
