import React, { Component } from 'react'

export default class SkillsExperiences extends Component {
    render() {
        if (this.props.children) {
            return (
                <div id={`${this.props.id ? this.props.id : "Skills"}`} className={`w-full min-h-screen ${this.props.className ? this.props.className : ""}`}>
                    {this.props.children}
                </div>
            )
        }
        return (
            <div id={`${this.props.id ? this.props.id : "Skills"}`} className={`w-full min-h-screen ${this.props.className ? this.props.className : ""}`}>
                <h2>Skills & Experience</h2>
            </div>
        )
    }
}
