import React, { Component } from 'react'

export default class Home extends Component {
	render() {
		return (
			<div id={this.props.id ? this.props.id : ""} className={`min-h-screen w-full ${this.props.className ? this.props.className : ""}`}>
				{this.props.children}
			</div>
		)
	}
}

