import React, { Component } from 'react'
import { XMarkIcon, GlobeAltIcon } from '@heroicons/react/24/outline';
import GithubIcon from './GithubIcon';
import HtmlContent from './HtmlContent';


export default class project extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modalOpened: false
        }
    }

    toggleModal = () => {
        if (this.state.modalOpened) {
            this.setState({ modalOpened: false })
        } else {
            this.setState({ modalOpened: true });
        }
        // alert("Modal toggled");
    }

    render() {
        return (
            <div className={`${this.props.className ? this.props.className : "w-full h-auto xl:w-full xl:aspect-square"}`}>
                <img className='w-full h-auto object-cover object-center cursor-pointer xl:aspect-square xl:m-0' src={`${this.props.src ? this.props.src : ""}`} alt={`${this.props.alt ? this.props.alt : ""}`} onClick={this.toggleModal} />
                <div id="modal" className={`modal ${this.state.modalOpened ? "modal--open" : ""}`}>
                    <XMarkIcon className='h-10 w-10 absolute right-0 line-current text-background cursor-pointer' onClick={this.toggleModal} />
                    <img className='w-full h-auto my-0 xl:h-[65%] xl:shrink-0 xl:grow-0 xl:object-cover xl:object-center xl:my-0' src={`${this.props.src ? this.props.src : ""}`} alt={`${this.props.alt ? this.props.alt : ""}`} />
                    <main className='px-2.5 pb-2.5 overflow-y-auto xl:min-h-min xl:h-[35%] xl:shrink xl:grow-0npm '>
                        <section>
                            <h2 className='text-2xl'>
                                {this.props.projectTitle ? this.props.projectTitle : "Test"}
                            </h2>
                        </section>
                        <section>
                            <HtmlContent html={this.props.content} />
                        </section>
                    </main>
                    <footer className='flex flex-row justify-between items-center px-2.5 pb-2.5 pt-4'>
                        {this.props.github_url &&
                            <a href={this.props.github_url} target="_blank" rel='noreferrer' className='dark:text-inherit text-primary'>
                                <GithubIcon className={`h-8 w-8 fill-current inline-block`} />
                                &nbsp;
                                View on GitHub
                            </a>
                        }

                        {this.props.live_url &&
                            <a href={this.props.live_url} target='_blank' rel='noreferrer' className='dark:text-inherit text-primary'>
                                <GlobeAltIcon className='h-8 w-8 inline-block' />
                                &nbsp;
                                View Live
                            </a>
                        }
                    </footer>
                </div>
            </div>
        )
    }
}
