import React, { Component } from "react";

export default class Button extends Component {
    render() {
        return (
            <a
                href={this.props.href}
                className={`bg-primary hover:bg-primary-dark text-background rounded-full px-4 py-3 font-bold ${this.props.className ? this.props.className : ""}`}
                title={this.props.title ? this.props.title : this.props.buttonName ? this.props.buttonName : ""}>
                {this.props.buttonName ? this.props.buttonName : "Untitled button"}
            </a>
        )
    }
}