import React, { Component } from 'react'

export default class Contact extends Component {
    render() {
        if (this.props.children) {
            return (
                <div id={`${this.props.id ? this.props.id : "Work"}`} className={`${this.props.className ? this.props.className : ""} min-h-screen w-full`}>
                    {this.props.children}
                </div>
            )
        }
        return (
            <div id={`${this.props.id ? this.props.id : "Work"}`} className={`${this.props.className ? this.props.className : ""} min-h-screen w-full`} >
                <h2>Contact</h2>
            </div>
        )
    }
}
