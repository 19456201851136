import React, { Component } from 'react'

export default class ExperienceCard extends Component {
    render() {
        if (this.props.children) {
            return (
                <div className={`rounded-xl shadow ${this.props.className ? this.props.className : ""}`}>
                    {this.props.children}
                </div>
            )
        }
        return (
            <div className={`rounded-xl shadow ${this.props.className ? this.props.className : ""}`}>
                <h3>{this.props.head ? this.props.head : "Experience title"}</h3>
                <h4>{this.props.company ? this.props.company : "Company name"}</h4>
                <p>
                    {this.props.description ? this.props.description : "Experience Description"}
                </p>
            </div>
        )
    }
}
