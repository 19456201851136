import React, { Component } from 'react'
import { HomeIcon, UserIcon, AcademicCapIcon, BriefcaseIcon, Bars4Icon } from '@heroicons/react/24/outline'


export class Navbar extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isOpened: false
		}
	}

	toggleNav = () => {
		if (this.state.isOpened === true) {
			this.setState({ isOpened: false });
		} else {
			this.setState({ isOpened: true });
		}
		console.log(this.state.isOpened);
	}
	render() {
		return (
			<div className=' 
			bg-gradient-to-r 
			from-primary 
			to-primary-dark
			w-auto
			max-w-max
			flex
			flex-col
			items-center
			justify-center
			min-h-screen
			h-full
			'>

				{/* menu */}
				<ul className={`hidden w-auto xl:flex flex-col justify-center gap-2.5 items-center h-full p-3.5 text-white`}>
					<li>
						<a href={this.props.homePage ? this.props.homePage : "*"} title='Home'>
							<HomeIcon className='h-6 w-6 stroke-current' />
						</a>
					</li>
					<li>
						<a href={this.props.aboutPage ? this.props.aboutPage : "*"} title='About'>
							<UserIcon className='h-6 w-6 stroke-current' />
						</a>
					</li>
					<li>
						<a href={this.props.skillsPage ? this.props.skillsPage : "*"} title='Skills'>
							<AcademicCapIcon className='h-6 w-6 stroke-current' />
						</a>
					</li>
					<li>
						<a href={this.props.workPage ? this.props.workPage : "*"} title='Work'>
							<BriefcaseIcon className='h-6 w-6 stroke-current' />
						</a>
					</li>
				</ul>
				{/* Mobile menu icon */}
				<div className='absolute left-2.5 top-2.5 xl:hidden' onClick={this.toggleNav}>
					<Bars4Icon className={`h-6 w-6 stroke-current ${this.state.isOpened ? "text-white" : "text-primary dark:text-background"}`} />
				</div>

				{/* mobile menu */}
				<ul className={`${this.state.isOpened === false ? "menu--closed" : ""} flex flex-col justify-center gap-2.5 items-center h-full p-3.5 text-white xl:hidden`}>
					<li>
						<a href={this.props.homePage ? this.props.homePage : "*"} title='Home'>
							<HomeIcon className='h-6 w-6 stroke-current' />
						</a>
					</li>
					<li>
						<a href={this.props.aboutPage ? this.props.aboutPage : "*"} title='About'>
							<UserIcon className='h-6 w-6 stroke-current' />
						</a>
					</li>
					<li>
						<a href={this.props.skillsPage ? this.props.skillsPage : "*"} title='Skills'>
							<AcademicCapIcon className='h-6 w-6 stroke-current' />
						</a>
					</li>
					<li>
						<a href={this.props.workPage ? this.props.workPage : "*"} title='Work'>
							<BriefcaseIcon className='h-6 w-6 stroke-current' />
						</a>
					</li>
				</ul>

			</div>
		)
	}
}
