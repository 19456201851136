import React, { Component } from 'react'

import Project from '../../src/components/project';
import axios from 'axios';

export default class Work extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projects: [],
        }
    }
    componentDidMount() {
        this.fetchProjects('https://fd-hendriks.nl/cms/wp-json/wp/v2/projects');
    }

    fetchProjects = (url) => {
        axios.get(url, {
            params: {
                _embed: true,
                per_page: 100
            }
        })
            .then(response => {
                console.log(response);
                this.setState({
                    projects: response.data
                });
            })
            .catch(err => {
                console.log(err)
            })
    }


    render() {
        if (this.props.children) {
            return (
                <div id={`${this.props.id ? this.props.id : "Work"}`} className={`${this.props.className ? this.props.className : ""} min-h-screen w-full`}>
                    {this.props.children}
                </div>
            )
        }
        return (
            <div id={`${this.props.id ? this.props.id : "Work"}`} className={`${this.props.className ? this.props.className : ""} min-h-screen w-full`}>
                <h2 className='xl:col-span-full'>Work</h2>

                {
                    this.state.projects.map((project, i) => {
                        let featuredMedia = project._embedded['wp:featuredmedia'][0].source_url;
                        console.log(featuredMedia);
                        console.log(project.metadata.fd_live_url[0]);
                        console.log(project.metadata.fd_github_url[0]);
                        return (<Project
                            key={project.title.rendered}
                            src={featuredMedia}
                            projectTitle={project.title.rendered}
                            content={project.content.rendered}
                            live_url={project.metadata.fd_live_url[0]}
                            github_url={project.metadata.fd_github_url[0]}
                        />)
                    })
                }

            </div>
        )
    }
}
