// import (normal) components
import {Navbar} from "./components/Navbar";
import Header from "./components/Header";
import Button from "./components/Button";
import Markdown from "./components/Markdown";
import RoundedImage from "./components/RoundedImage";
import ExperienceCard from "./components/ExperienceCard";
// Import page components
import {UnderConstruction} from "./components/UnderConstruction";
import Home from "./pages/Home";
import About from "./pages/About";
import SkillsExperiences from "./pages/SkillsExperiences";
import Work from "./pages/Work";
import Contact from "./pages/Contact";
// Other imports
import image from "./assets/under-construction.webp";
import persona from "./assets/IMG20220428211817.jpg";


function App() {
    console.log(process.env.REACT_APP_CONSTRUCTION);

    if (process.env.REACT_APP_CONSTRUCTION === true) {
        return (
            <UnderConstruction/>
        )
    }

    return (
        <div className="w-screen h-screen flex flex-row bg-background dark:bg-background-dark">
            <Navbar homePage="#Home" aboutPage="#aboutMe" skillsPage="#Skills" workPage="#myWork"/>
            <div className="min-h-screen w-full overflow-y-scroll scroll-smooth">
                <Home id="Home" className={`px-2.5 py-5 flex flex-col justify-center items-center text-center`}>
                    <Header
                        className={`prose-sm text-secondary dark:text-background prose-headings:text-primary prose-headings:dark:text-background prose-headings:font-bold w-full max-w-none xl:prose-xl xl:font-bold`}>
                        <h1>Fabian Hendriks</h1>
                        <p>A web developer who likes back-end the most but also loves translating designs to functional
                            responsive webpages</p>
                    </Header>
                    <div className={`w-full flex flex-row justify-around py-2.5 xl:justify-center xl:space-x-4`}>
                        <Button className={`text-xs self-start xl:text-xl xl:px-12 py-3`} buttonName="View my skills"
                                title="Go the skills and experiences section" href="#Skills"/>
                        <Button className={`text-xs self-end xl:text-xl xl:px-12 py-3`} buttonName="View my work" title="Go to my Work section"
                                href="#myWork"/>
                    </div>
                </Home>
                <About id="aboutMe"
                       className={`px-2.5 py-5 text-secondary dark:text-background flex flex-col text-center prose-sm prose-headings:font-bold prose-headings:text-primary dark:prose-headings:text-background xl:prose-xl xl:flex xl:flex-row-reverse xl:justify-around xl:items-center xl:gap-x-16 xl:px-32 2xl:gap-x-32`}>
                    <RoundedImage source={persona} alt={`Under Construction`}
                                  className="h-32 w-32 mx-auto my-0 xl:w-52 xl:h-52 xl:shrink-0 xl:grow-0 object-cover"/>
                    <Markdown fileName="about.md" className={`xl:grow xl:shrink`}/>
                </About>
                <SkillsExperiences id="Skills"
                                   className={`px-2.5 py-5 text-secondary dark:text-background text-center prose-sm prose-headings:font-bold prose-headings:text-primary dark:prose-headings:text-background xl:grid xl:grid-cols-2 xl:prose-xl xl:px-8 xl:justify-between xl:gap-x-4 2xl:px-32 2xl:gap-x-8`}>
                    <Markdown fileName="skills_experience.md"/>
                    <div
                        className="space-y-2.5 xl:grid xl:grid-cols-2 xl:space-y-0 xl:space-x-0 xl:auto-rows-fr xl:gap-6">
                        <ExperienceCard className="shadow-primary dark:shadow-background px-4 pb-4 pt-1">
                            <h3>Internship Back-end Developer</h3>
                            <h4>
                                Searchtrends Holding
                            </h4>
                            <p>
                                feb 2021 - jul 2021
                            </p>
                            <p>
                                Programming a multi-CMS like structure in Laravel 8 while also learning more about many
                                to many database relationships, routing, TailwindCSS, VueJS, MVC(Model View Controller)
                                & more.
                            </p>
                        </ExperienceCard>
                        <ExperienceCard className="shadow-primary dark:shadow-background px-4 pb-4 pt-1">
                            <h3>Back-end Developer</h3>
                            <h4>Searchtrends Holding</h4>
                            <p>jul 2021 - sep 2021</p>
                            <p>
                                Summer job at the company where i did my first internship as a developer. during this
                                summer job i spent most time continuing the development on the multi-cms structure i
                                developed during my internship there as well as developing some of the sites that would
                                make use of the CMS.
                            </p>
                        </ExperienceCard>
                        <ExperienceCard className="shadow-primary dark:shadow-background px-4 pb-4 pt-1">
                            <h3>Internship Back-end/WordPress Developer</h3>
                            <h4>FBI Design/FBI Digital Haarlem</h4>
                            <p>sep 2021 - feb 2022</p>
                            <p>
                                Internship were i learned to program in custom WordPress themes, create custom
                                post-types and even collaborated on a plug-in made by the company to work with changes
                                in the API used by the plug-in.
                            </p>
                        </ExperienceCard>
                        <ExperienceCard className="shadow-primary dark:shadow-background px-4 pb-4 pt-1">
                            <h3>Back-end/WordPress Developer</h3>
                            <h4>Vrije Universiteit (VU) Amsterdam</h4>
                            <p>
                                jun 2022 - jan 2023
                            </p>
                            <p>
                                After building a custom WordPress theme together with two fellow students from the
                                graphic design course as final assignment of graduating from Mediacollege Amsterdam. The
                                people of VU-AMS a project within VU Amsterdam approached me asking if i would like to
                                continue developing the WordPress theme even further for them.
                                The final results can be found <a title="View site" href="https://vu-ams.nl"
                                                                  target="_blank" rel="noreferrer">Here</a>.
                            </p>
                        </ExperienceCard>
                    </div>
                </SkillsExperiences>
                <Work id="myWork"
                      className={`px-2.5 py-5 text-secondary dark:text-background flex flex-col text-center prose-sm prose-headings:font-bold prose-headings:text-primary dark:prose-headings:text-background xl:min-h-fit xl:grid xl:grid-cols-4 xl:auto-rows-auto xl:px-8 xl:gap-6 2xl:px-32 xl:prose-xl`}/>
                <Contact id="contactMe"
                         className={`px-2.5 py-5 text-secondary dark:text-background flex flex-col items-center text-center prose-sm prose-headings:font-bold prose-headings:text-primary dark:prose-headings:text-background prose-a:my-2 xl:prose-xl xl:px-8 2xl:px-32 xl:flex-row xl:flex-wrap xl:justify-around xl:items-start xl:min-h-fit`}>
                    <h2 className={`xl:basis-full xl:shrink-0 xl:grow-0`}>Contact me</h2>
                    <p className={`xl:basis-full xl:grow-0 xl:shrink-0`}>
                        Did i pique your interest?
                        If so you can contact me by emailing
                        me or via LinkedIn
                    </p>
                    <a href='mailto:fabian.et.je@hotmail.com'
                       className="bg-primary text-background rounded-full grow-0 max-w-max font-bold px-8 py-3 xl:px-12">
                        Send an Email
                    </a>
                    <a href="https://www.linkedin.com/in/fabian-hendriks-7772601b1/" target={`_blank`}
                       className={`bg-primary text-background rounded-full grow-0 max-w-max font-bold px-8 py-3 xl:px-12`}>
                        Go to LinkedIn
                    </a>
                </Contact>

            </div>
        </div>
    )

}

export default App;
