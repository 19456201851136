import React from 'react'
import UnderConstructionImg from '../assets/under-construction.webp'

export const UnderConstruction = () => {
    return (
        <div className='w-screen px-8 lg:px-0 h-screen flex flex-col justify-center items-center bg-white prose-sm md:prose !max-w-full prose-headings:text-primary xl:prose-headings:text-primary text-center'>
            <div className='w-3/4 xl:w-1/4'>
                <img src={UnderConstructionImg} alt='Under construction image' />
            </div>
            <div>
                <h2> sorry this site is currently under construction...
                    Please come back later</h2>

            </div>
        </div>
    )
}
