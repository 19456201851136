import React, { Component } from 'react'

export default class RoundedImage extends Component {
    render() {
        return (
            <img className={`rounded-full border-2 border-accent dark:border-background ${this.props.className ? this.props.className : "w-[125px] h-[125px]"}`}
                src={`${this.props.source ? this.props.source : ""}`}
                alt={`${this.props.alt ? this.props.alt : ""}`} />
        )
    }
}
