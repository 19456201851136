import React, { Component } from "react";

export default class About extends Component {
    render() {

        if (this.props.children) {
            return (
                <div id={`${this.props.id ? this.props.id : "About"}`} className={`${this.props.className ? this.props.className : ""} w-full min-h-screen`}>
                    {this.props.children}
                </div>
            )
        }
        return (
            <div id={`${this.props.id ? this.props.id : "About"}`} className={`${this.props.className ? this.props.className : ""} w-full min-h-screen`}>
                <h2>About</h2>
            </div>
        )

    }
}
