import React, { Component } from 'react';
import Showdown from 'showdown';
import parse from 'html-react-parser';
// import ''

export default class Markdown extends Component {
    constructor(props) {
        super(props);
        this.state = { md: "" }
    }

    async componentDidMount() {
        const path = "./Markdown";
        const file = await import(`${path}/${this.props.fileName}`);
        const response = await fetch(file.default);
        const text = await response.text();

        this.setState({
            md: text
        });

    }
    render() {
        let md = this.state.md;
        const converter = new Showdown.Converter();
        return (
            <div className={`${this.props.className ? this.props.className : ""}`}>
                {parse(converter.makeHtml(md))}
            </div>
        )
    }
}
